<script setup lang="ts">
import type { paymentEntity } from '@/entities/payment'

const emit = defineEmits(['close', 'error'])

const props = defineProps({
  bundle: {
    type: String as () => paymentEntity.types.PaymentBundle,
    default: '',
  },
})

onMounted(() => {
  if (!props.bundle) return emit('error')
  console.log('TODO')
})
</script>

<template>
  <div class="flex min-h-screen items-center justify-center p-20">TODO: Success</div>
</template>
