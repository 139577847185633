<script setup lang="ts">
import { paymentEntity } from '@/entities/payment'

type IframeEvent = {
  data: string
}

const emit = defineEmits(['close', 'error', 'failure', 'success'])

const props = defineProps({
  bundle: {
    type: String as () => paymentEntity.types.PaymentBundle,
    default: '',
  },
})

const DELAY_ORDER_STATUS_CHECK = 1000
const LIMIT_ORDER_STATUS_CHECK = 3

const state = reactive({
  loading: true,
  invoiceId: '',
  src: '',
  countChecks: 0,
})

onMounted(() => {
  if (!props.bundle) return emit('error')
  initForm()
  window.addEventListener('message', listenFrame, false)
})

onBeforeUnmount(() => {
  window.removeEventListener('message', listenFrame, false)
})

async function initForm() {
  const response = await paymentEntity.model.invoiceCreateInApp(props.bundle, 'test payment')
  if (!response) return emit('failure')
  state.loading = false
  state.src = response.gateway.url
  state.invoiceId = response.id
}

function listenFrame(event: MessageEvent<IframeEvent>) {
  if (!event.data) return
  if (typeof event.data !== 'string') return
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const data = JSON.parse(event.data || '{}')

  if (data.message === 'close-button') {
    closeFrame()
  }

  // Обробка переходу на мобільному пристрої через діпклінк
  if (data.message === 'monopay-link') {
    window.open(data.value, '_blank')
  }
}

async function closeFrame() {
  state.src = ''
  state.loading = true
  const result = await checkInvoiceStatus()
  if (result) emit('success')
  else emit('error')
  state.loading = false
}

async function checkInvoiceStatus() {
  if (state.countChecks >= LIMIT_ORDER_STATUS_CHECK) return false
  const response = await paymentEntity.model.invoiceStatus(state.invoiceId)
  if (!response) return false
  if (response.status === 'created' || response.status === 'processing') {
    await delayInMs(DELAY_ORDER_STATUS_CHECK)
    state.countChecks++
    return checkInvoiceStatus()
  }
  if (response.status === 'success') return true
  return false
}
</script>

<template>
  <div class="flex min-h-screen items-center justify-center p-20">
    <iframe
      v-if="state.src"
      id="payFrame"
      title="monopay"
      width="100%"
      height="100%"
      :src="state.src"
      allow="payment *"
      class="rounded-24"
    />
    <div v-if="state.loading" class="w-full">
      <div class="skeleton mb-16 h-120 rounded-8" />
      <div class="skeleton mb-16 h-24 rounded-8" />
      <div class="skeleton h-60 rounded-8" />
    </div>
  </div>
</template>
