<script setup lang="ts">
import type { paymentEntity } from '@/entities/payment'

import { AppCloseButton } from '@/features/app'

import PaymentBaseBundles from './PaymentBaseBundles.vue'
import PaymentFailure from './PaymentFailure.vue'
import PaymentProcessing from './PaymentProcessing.vue'
import PaymentSuccess from './PaymentSuccess.vue'

type PaymentState = {
  show: boolean
  showClose: boolean
  entry: paymentEntity.types.PaymentEntry
  bundle: paymentEntity.types.PaymentBundle
  screen: paymentEntity.types.PaymentScreen
}

const state = reactive<PaymentState>({
  show: false,
  showClose: true,
  entry: '',
  screen: '',
  bundle: '',
})

const modal = useModal()

modal.on('payment_open', (payload) => {
  if (state.show) return
  state.entry = payload.entry
  state.bundle = 'base_4200_annual'
  state.screen = payload.screen || 'base_bundles'
  state.showClose = true
  state.show = true
})

function onClose() {
  state.show = false
}

function onPurchase() {
  state.showClose = false
  state.screen = 'processing'
}

function onProcessingClose() {
  state.showClose = true
  state.screen = 'base_bundles'
}

function onProcessingSuccess() {
  state.showClose = true
  state.screen = 'success'
}

function onProcessingError() {
  state.showClose = true
  state.screen = 'base_bundles'
}

function onProcessingFailure() {
  state.showClose = true
  state.screen = 'failure'
}
</script>

<template>
  <Transition name="fade">
    <div
      v-if="state.show"
      class="fixed inset-0 flex flex-col overflow-x-hidden overflow-y-scroll overscroll-none bg-white"
    >
      <AppCloseButton v-if="state.showClose" class="absolute right-16 top-16" @click="onClose" />
      <PaymentBaseBundles
        v-if="state.screen === 'base_bundles'"
        :bundle="state.bundle"
        @bundle="state.bundle = $event"
        @purchase="onPurchase"
      />
      <PaymentProcessing
        v-else-if="state.screen === 'processing'"
        :bundle="state.bundle"
        @close="onProcessingClose"
        @error="onProcessingError"
        @success="onProcessingSuccess"
        @failure="onProcessingFailure"
      />
      <PaymentSuccess v-else-if="state.screen === 'success'" :bundle="state.bundle" />
      <PaymentFailure v-else-if="state.screen === 'failure'" :bundle="state.bundle" />
    </div>
  </Transition>
</template>
